<template>
  <div class="log ">
  <div class="container-fluid row  col-lg-12    ">
    <div class="  col-lg-6  col-md-6 col-sm-6  container leftbox  "  >
      <div class="   " >
        <img src="../assets/art.png" alt=""  style="width: 100%; height: 20%; " class="img2">
        <p class="mb-3 welcome"> Welcome  👋   </p>
        <p  style="color: 
         #313957; font-size: 20px;">Today is new day.It's your day.You Shape it. <br>Sign in to start managing your projects.</p>
         
      <div class="form-group my-5   ">
        <label for="name">Email</label>
        <input
          type="text"
          class="form-control form-control-lg  mb-3 inp"
          id="name" placeholder="Example@gmail.com"
          v-model="formdata.email_id"
          :class="{ 'is-invalid':  email_idError}"
        />
        <small class="text-danger">{{  email_idError }}</small>
      
      
        <label for="password">Password</label>
  
        <input
          :type="showpassword ? 'text' : 'password'"
          class="form-control mb-3 form-control-lg inp "
          id="password" placeholder="Atleast 8 Characters"
          v-model="formdata.password"
          :class="{ 'is-invalid': passwordError }"
        />
        <div class="d-flex justify-content-between">
          <span
          class="bi bi-eye-slash eye"
          @click="showpassword = !showpassword"
        ></span>
        <a href="" style="color:#5D5FEF ; text-decoration: none;">Forgot password?</a>
        </div>
       
        <small class="text-danger">{{ passwordError }}</small>
      </div>
      
      <button class="signin  w-100 text-white"  @click="gotonameoftheApplicationpage"> Sign in</button>
      <p style="font-size: 18px;"  class="d-flex justify-content-center">Don't you have an account ? <span><a href="" style="color:#5D5FEF ; text-decoration: none;">Sign up</a></span></p>
      
     
      </div>
    </div>
    
    <div class="col-lg-6  col-md-6 col-sm-6  d-flex justify-content-center align-items-center  ">
      <img src="../assets/art.png" alt=""  class="img container ">
    </div>
    </div>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  export default {
    name: "login-page",
    data() {
      return {
        apiUrl: process.env.VUE_APP_API_BASE_URL,
       
        formdata: {
          email_id: "",
          password: "",
        },
        email_idError: "",
        passwordError: "",
        showpassword: false,
      };
    },
    methods: {
      validateForm() {
        this. email_idError = this.formdata. email_id ? "" : "Name is required";
        this.passwordError = this.formdata.password
          ? this.formdata.password.length >= 8
            ? ""
            : "password must be at least 8 characters"
          : "Password  is required";
  
        return !this. email_idError && !this.passwordError;
      },
  
      gotoregister() {
        if (this.validateForm()) {
          this. gotonameoftheApplicationpage();
        }
      },
      async gotonameoftheApplicationpage() {
        // const token = localStorage.getItem("authToken");
        // const headers = {
        //   Authorization: `Bearer ${token}`,
          
        // };
       
          const response = await axios.post(
         this.apiUrl + "/login",
            this.formdata, 
            
          );
        
          localStorage.setItem('loginresponse',JSON.stringify(response.data))
          localStorage.setItem("email_id", this.formdata.email_id); 
       
          
          this.$router.push('/sidebar')
       
      },
    },
  };
  </script>
  
  <style scoped>
 
  .leftbox{
    display: grid;
    place-content: center;
  font-family: regular;
  
  }
  .welcome{
    font-family: rounded;
    font-size: 36px;
    font-weight: 600;
  }
  ::placeholder {
  color: #8897AD;
  font-size: 80%;

  
}
.inp
{
  background-color: #F7FBFF;
  
}
.img{
  width: 90%;
  height: 90%;
 
  
  

 
 
  
}
.signin{
  background-color: #5D5FEF;
  border-radius: 10px;
  border: #4d3ce6;
  height: 40px;
  cursor: pointer;
   transition-duration: 0.4s;
  

}
.signin:hover{
  background-color: #5444ec;

}
  

  
  @media (max-width: 575px) {
    .img{
      display: none;
    }
    
  }
  @media (min-width: 576px) {
    .img2{
      display: none;
    }
    
  }
  
 
  
  </style>