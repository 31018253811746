import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import PrimeVue from 'primevue/config';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Row from 'primevue/row';   
import "primeflex/primeflex.css";


import "primevue/resources/themes/saga-blue/theme.css"; 
import "primevue/resources/primevue.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import "primeicons/primeicons.css";
import InputText from 'primevue/inputtext';
import Paginator from 'primevue/paginator';
import ColumnGroup from 'primevue/columngroup';  

createApp(App).use(store).use(router).use(PrimeVue).component('ColumnGroup' , ColumnGroup).component('Paginator' , Paginator).component('DataTable', DataTable).component('Row', Row).component('Column', Column).component('InputText', InputText).mount('#app');
