<template>
    <div>
        <div class="sidenav shadow-lg ">
            <img src="../assets/logo2.jpg" alt="" class="imge ">
            <ul>
                <li v-for="(item, index) in sidebarItems" :key="index">
                    <router-link :to="item.link" active-class="active-link">
                        <i :class="item.icon + ' p-2'"></i>{{ item.name }}

                    </router-link>
                </li>
            </ul>
        </div>
        <div class="imge2-container d-flex my-2">
            <img src="../assets/pro.png" alt="" class="imge2  mx-4  ">
            <div class=" ">
                <p class="email">{{ email }} <span @click="toggleBoxVisible = !toggleBoxVisible"><i
                            class="bi bi-chevron-down"></i></span></p>
                <div v-if="toggleBoxVisible" class="small-box">
                    <ul>
                        <li>
                            <a href="" @click="logout">Logout</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'sidebar-page',
    data() {
        return {
            sidebarItems: [],
            apiUrl: process.env.VUE_APP_API_BASE_URL,
            loggedIn: false,
            email: "",
            toggleBoxVisible: false

        }
    },
    mounted() {
        const storedObject = localStorage.getItem("loginresponse");
        const loginResponse = JSON.parse(storedObject);
        if (loginResponse && loginResponse.data) {
            let keys = Object.keys(loginResponse.data);
            keys.forEach(key => {
                let formattedKey = key.replace('_', ' & ');
                if (formattedKey === 'quotation') {
                    formattedKey = 'estimate';
                }
                let icon = '';
                switch (key) {
                    case 'user':
                        icon = 'bi bi-person-fill';
                        break;
                    case 'company':
                        icon = 'bi bi-building-fill';
                        break;
                    case 'jobwork':
                        icon = 'bi bi-box-fill';
                        break;
                    case 'terms_condition':
                        icon = 'bi bi-clipboard-check-fill';
                        break;
                    case 'product':
                        icon = 'bi bi-nut-fill';
                        break;
                    case 'unit':
                        icon = 'bi bi-caret-up-square-fill';
                        break;
                    case 'quotation':
                        icon = 'bi bi-calculator-fill';
                        break;
                }

                this.sidebarItems.push({
                    name: formattedKey.charAt(0).toUpperCase() + formattedKey.substr(1).toLowerCase() + 's',
                    link: '/' + key,
                    icon: icon
                })
            })
        }
        this.retrieveEmail();

    },
    methods: {
        retrieveEmail() {
            const email = localStorage.getItem("email_id");
            if (email) {
                this.email = email;
            }
        },
        logout() {

            localStorage.removeItem("email_id");
            localStorage.removeItem("loginresponse");

            this.$router.push("/");
        }
    }

}
</script>


<style scoped>
.active-link {
    background-color: #7364FF;
    color: #ffffff !important;
    padding: 9px;
    border-radius: 5px;
}

li,
a {
    list-style: none;
    text-decoration: none;
    color: #7364FF;
}

.small-box {
    background-color: #ffffff;
    padding: 12px;
    border: 1px solid #ebe7e7;
    border-radius: 3px;
    margin-top: 5px;
}

.sidenav {
    width: 320px;
    height: 100%;
    position: fixed;
    background-color: #ffffff;
    padding-left: 10px;
    padding-right: 15px;
}

.email {
    margin-top: 10px;
    left: 20px;
    font-size: 18px;
    color: #5d7a9b;
}

.sidenav li a {
    text-decoration: none;
    color: #3B424A;
    font-size: 22px;
}

.sidenav li a:hover {
    background-color: #7364FF;
    padding: 8px;
    border-radius: 5px;
    color: #ffffff;
}


.sidenav li {
    list-style: none;
    padding: 15px;
    font-family: displayregular;
}

.imge {
    border-radius: 50%;
    width: 50px;
    margin-top: 20px;
    margin-left: 40px;
    margin-bottom: 70px;
    border-style: double;
    border-color: rgb(5, 68, 37);
    height: 45px;
}

.imge2-container {
    position: absolute;
    right: 20px;
}

.imge2 {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    border-style: double;
    border-color: rgb(5, 68, 37);
}


@media screen and (max-width: 368px) {
    .sidenav {
        width: 100%;
        z-index: 1;
    }

    .router {
        margin-left: 250px;
    }
}
</style>
