
<template>
    <div>
        <template v-if="route.path !== '/' ">
        <sidebar />
        
        </template>
        
        <div class="router">
            
                <router-view />
                
            </div>



      <div>
        
      </div>
    </div>
  </template>
<script lang="ts">
import sidebar from '@/views/sidebar.vue'
import { defineComponent } from 'vue'
import { useRoute } from 'vue-router' 


export default defineComponent({
  components: {
    sidebar
  },
  setup() {
    const route = useRoute()
    return { route }
  }
})
</script>


<style lang="scss">

@font-face {
  font-family: rounded;
  src: url('./fonts/SF-Pro-Rounded-Bold.otf');
 
}
@font-face {
  font-family: regular;
  src: url('./fonts/SF-Pro-Rounded-Regular.otf');
}
@font-face {
  font-family: inter;
  src: url('./fonts/Inter-VariableFont_slnt\,wght.ttf');
}
@font-face {
  font-family:public ;
  src: url('./fonts/PublicSans-VariableFont_wght.ttf');
};
@font-face {
    font-family: displaymedium;
    src: url('./fonts/SFPRODISPLAYMEDIUM.OTF');
}
@font-face {
    font-family: displaybold;
    src: url('./fonts/SFPRODISPLAYBOLD.OTF');
}
@font-face {
    font-family: displayregular;
    src: url('./fonts/SFPRODISPLAYREGULAR.OTF');
}
.router{
    flex-grow: 1; 
    background: #ffffff;
  
  padding-top: 70px;
  padding-left : 30px
}
label{
    font-family: displaymedium;
    color: #070A13;
    font-weight: 400;
    font-size: 14px;
   
}

.dim-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
    z-index: 999; 
}
.button {
    background-color: #aea6f8;
    border: none;
    color: white;
    padding: 10px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    transition-duration: 0.4s;
    cursor: pointer;
    border-radius: 5px;
    
   

    
    

}

.button1 {
    background-color: #7364FF;
    color: #F5F5FA;
    border: 2px solid #9389e7;
  

}

.button1:hover {
    background-color: #4d3ce6;
    color: white;
}


.box {
    
    background: white;
    text-transform: capitalize;
   }
::placeholder{
    font-size: 16px;
    font-weight: 50;
    font-family: displayregular;
    color:#77879c ;
    
}

.table-wrapper {
    margin: 10px ;
    // box-shadow: 10px 35px 50px rgba(0, 0, 0, 0.2);
}

.fl-table {
    border-radius: 5px;
    font-size: 20px;
    font-weight: normal;
    border: none;
    border-collapse: collapse;

    white-space: nowrap;
    background-color: white;
}

.fl-table td,
.fl-table th {
    text-align: center;
    padding: 30px;
    width: 30%;
 
}

.fl-table td {
    border-right: 1px solid #f8f8f8;
    font-size: 18px;
    font-family: inter;
}

.fl-table thead th {
    color: #4D515A;
    background:
        #F8FAFC;
    font-family: inter;
}



.fl-table tr:nth-child(even) {
    background: #F8F8F8;
}



@media (max-width: 767px) {
    .fl-table {
        display: block;
        width: 100%;
    }

    .table-wrapper:before {
        content: "Scroll horizontally >";
        display: block;
        text-align: right;
        font-size: 11px;
        color: white;
        padding: 0 0 10px;
    }

    .fl-table thead,
    .fl-table tbody,
    .fl-table thead th {
        display: block;
    }

    .fl-table thead th:last-child {
        border-bottom: none;
    }

    .fl-table thead {
        float: left;
    }

    .fl-table tbody {
        width: auto;
        position: relative;
        overflow-x: auto;
    }

    .fl-table td,
    .fl-table th {
        padding: 20px .625em .625em .625em;
        height: 60px;
        vertical-align: middle;
        box-sizing: border-box;
        overflow-x: hidden;
        overflow-y: auto;

        font-size: 13px;
        text-overflow: ellipsis;
    }

    .fl-table thead th {
        text-align: left;
        border-bottom: 1px solid #f7f7f9;
    }

    .fl-table tbody tr {
        display: table-cell;
    }

    .fl-table tbody tr:nth-child(odd) {
        background: none;
    }

    .fl-table tr:nth-child(even) {
        background: transparent;
    }

    .fl-table tr td:nth-child(odd) {
        background: #F8F8F8;
        border-right: 1px solid #E6E4E4;
    }

    .fl-table tr td:nth-child(even) {
        border-right: 1px solid #E6E4E4;
    }

    .fl-table tbody td {
        display: block;
        text-align: center;
    }
}

.first-column-color {
    color: #7364FF;
}

.fivth-column-color {
    color: #7364FF;
}

.edit-dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    background-color: white;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

}

.dialog-content {
    max-width: 900px;
    margin: 0 auto;

}

.buttonn {
    background-color: #aea6f8;
    border: none;
    color: white;
    padding: 7px 28px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    transition-duration: 0.4s;
    cursor: pointer;
    border-radius: 5px;

}

.button2 {
    background-color: #7364FF;
    color: #F5F5FA;
    border: 2px solid #9389e7;

}

.button2:hover {
    background-color: #4d3ce6;
    color: white;
}
.dustbin{
    color: red;
}
.fix{
  margin-left: 250px; 
  background: #F5F5FA;
 
  
}
@media screen and (max-width: 1024px ) {
    .fix{
        width: 100%;
    }
    
}
@media screen and (max-width: 768px ) {
    .fix{
        width: 150%;
    }
    
}

@media screen and (max-width: 425px ) {
    .fix{
        width: 250%;
    }
    
}
.addbutton {
    background-color: #aea6f8;
    border: none;
    color: white;
    padding: 7px 28px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    transition-duration: 0.4s;
    cursor: pointer;
    border-radius: 5px;

}

.addbutton {
    background-color: #7364FF;
    color: #F5F5FA;
    border: 2px solid #9389e7;

}

.addbutton:hover {
    background-color: #4d3ce6;
    color: white;
}

.addbutton2 {
    background-color: #aea6f8;
    border: none;
    color: white;
    padding: 3px 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 6px 4px;
    transition-duration: 0.4s;
    cursor: pointer;
    border-radius: 5px;

}

.addbutton2 {
    background-color: #7364FF;
    color: #F5F5FA;
    border: 2px solid #9389e7;

}

.addbutton2:hover {
    background-color: #4d3ce6;
    color: white;
}



</style>
