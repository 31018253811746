import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import login from '../views/login.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/' ,
    name: 'login',
    component: login
  },
  {
    path: '/user',
    name: 'usermaster',
   
    component: () => import( '../views/usermaster.vue'),
    
  },
  {
    path: '/company',
    name: 'companymaster',
   
    component: () => import( '../views/companymaster.vue'),
   
  },
  {
    path: '/jobwork',
    name: 'jobwork',
   
    component: () => import( '../views/jobwork.vue')
  },
  {
    path: '/terms_condition',
    name: 'terms_condition',
   
    component: () => import( '../views/termsandcondition.vue')
  },
  {
    path: '/product',
    name: 'product',
   
    component: () => import( '../views/product.vue')
  },
  {
    path: '/unit',
    name: 'unit',
   
    component: () => import( '../views/unit.vue')
  },
  {
    path: '/quotation',
    name: 'quotation',
   
    component: () => import( '../views/quotation.vue')
  },
  {
    path:'/dialogbox',
name:'userdialogbox',
component: ()=> import( '../views/userdialogbox.vue')
  }
  ,
  {
    path:'/companydialogbox',
name:'companydialogbox',
component: ()=> import( '../views/companydialogbox.vue')
  }
  ,
  {
    path:'/jobworkdialogbox',
name:'jobworkdialogbox',
component: ()=> import( '../views/jobworkdialogbox.vue')
  },
  {
    path:'/termsandconditionsdialogbox',
name:'termsandconditionsdialogbox',
component: ()=> import( '../views/termsandconditionsdialogbox.vue')
  },
  {
    path:'/productdialogbox',
name:'productdialogbox',
component: ()=> import( '../views/productdialogbox.vue')
  },
  {
    path:'/unitdialogbox',
name:'unitdialogbox',
component: ()=> import( '../views/unitdialogbox.vue')
  },
  {
    path:'/quotationdialogbox',
name:'quotationdialogbox',
component: ()=> import( '../views/quotationdialogbox.vue')
  },

 

  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
